import React from 'react'
import SideBar from '../Dashboard/SideBar'
import '../../accests/Css/Login.css'
import UserProfile from './Forms'

export default function Profile() {
  return (
    <div style={{overflowX:"hidden"}}>
      <SideBar/>
      <div >
      <UserProfile />
      </div>
    </div>
  )
}
