import React, { useState } from 'react'
import '../accests/Css/Login.css'
import { Link,  useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export default function Signup() {
    const [Name, setName] = useState("");
    const [Email, setEmail] = useState("");
    const [Password, setPassword] = useState("")
    const [Age, setAge] = useState("")
    const [image, setImage] = useState("")
    const Navigate = useNavigate()
    const handleSignup = (e) => {
        e.preventDefault()
        if (!Email || !Password || !Name || !Age || !image) {
            toast.error("Please Fill This Form");
        } else {
            toast.success("Signup Success");
            console.log(Name, Email, Password, Age, image);
            setName("")
            setEmail("")
            setAge("")
            setPassword("")
            setImage("")
            setTimeout(()=>{

                Navigate("/Login")
            },1000)
        }
    };
    return (
        <div className='dark'>
            <div id="main-container" >
                <div id="form-container" className='cus'>
                    <div id="content">
                        <span class="title-text">Signup</span>
                        <form id="login-form" onSubmit={handleSignup}>
                            <div class="field">
                                <input
                                    required=""
                                    type="text"
                                    value={Name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                                <label>Name</label>
                            </div>
                            <div class="field">
                                <input
                                    required=""
                                    type="Email"
                                    value={Email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <label>Email</label>
                            </div>
                            <div class="field">
                                <input
                                    required=""
                                    type="password"
                                    value={Password}
                                    onChange={(e) => setPassword(e.target.value)} />
                                <label>Password</label>
                            </div>
                            <div class="field">
                                <input
                                    required=""
                                    type="number"
                                    value={Age}
                                    onChange={(e) => setAge(e.target.value)} />
                                <label>Enter Your Age</label>
                            </div>
                            <div class="field">
                                <input required="" type="file"

                                    onChange={(e) => setImage(e.target.files[0])} />
                                <label>Upload Image</label>
                            </div>
                            <div id="action">
                                <label><input type="checkbox" />Remember me</label>
                            </div>
                            <button id="login">Signup</button>
                            <div id="signup">Already have an Account?
                                <Link to='/Login'>Sign up</Link>

                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>

    )
}
