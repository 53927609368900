import React, { useState } from 'react';
import SideBar from '../Dashboard/SideBar';
import { useLocation, useNavigate,  } from 'react-router-dom';

const DetailPage = () => {
  const Navigate = useNavigate()
  const [answers, setAnswers] = useState({});
  const { state } = useLocation();
  // const history = useHistory();
  console.log(state);

  const handleCheckboxChange = (questionId, option) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: Array.isArray(prevAnswers[questionId])
        ? [...prevAnswers[questionId], option]
        : [option],
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Extracting selected answers with their values
    const selectedAnswersWithValues = Object.entries(answers).map(([questionId, selectedOptions]) => ({
      questionId,
      selectedOptions: selectedOptions.map(option => option.title),
    }));

    // Logging selected answers with their values
    console.log('Submitted Answers:', selectedAnswersWithValues);

    // Show alert
    alert('Answers Submitted!');

    // Reset state and navigate back
    setAnswers({});
    Navigate("/Dashboard")
  };

  return (
    <div className="flex h-screen bg-gray-100">
      <SideBar />

      <div className="-ml-20 p-8 w-full mt-20">
        <h2 className="text-3xl font-bold mb-6 text-blue-600"> Questions And Answer </h2>
        <form onSubmit={handleSubmit}>
          <p className="text-2xl mb-4 text-gray-800 font-extrabold">Qno:{state.id} {state.title}</p>
          <div key={1} className="mb-8 bg-white p-6 rounded shadow">
            {state.options?.map((question) => (
              <div key={question.id}>
                <label className="flex items-center text-gray-700">
                  <input
                    type="checkbox"
                    onChange={() => handleCheckboxChange(question.id, question)}
                    checked={answers[question.id]?.includes(question)}
                    className="mr-2 form-checkbox text-blue-500"
                  />
                  <span className="text-lg">{question.title}</span>
                </label>

                {answers[question.id]?.length > 0 && (
                  <div className="mt-2">
                    <strong>Selected Answer(s):</strong>
                    {answers[question.id].map((selectedAnswer, index) => (
                      <span key={index} className="ml-2">
                        {selectedAnswer.title}
                      </span>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
          <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default DetailPage;
