import React from 'react'
import SideBar from './SideBar'
import Card from '../../Components/Card'
import '../../accests/Css/Login.css'

export default function DashBoard() {
  return (
    <div style={{overflowX:"hidden"}}>
      <SideBar/>
      <div className='left'>
      <Card/>
      </div>
    </div>
  )
}
