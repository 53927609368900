import React, { useEffect, useState } from "react";
import "../../accests/Css/forms.css";
import {
  Card,
  CardContent,
  IconButton,
  TextField,
  Tooltip,
  Input,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import CameraIcon from "@mui/icons-material/PhotoCamera";
import EditIcon from "@mui/icons-material/ModeEdit";
import SaveIcon from "@mui/icons-material/Save";
import axios from "axios";
import { BaseUrl } from "../../BaseUrl/Baseurl";
import { toast } from "react-toastify";

const Forms = () => {
  const token = localStorage.getItem("Token");
  const [bk, setBk] = useState({
    name: "",
    age: "",
    image: "", // assuming bk has an image property
  });
  const [viewMode, setViewMode] = useState(true);
  const [user, setUser] = useState({
    Name: "",
    Age: "",
    Password: "",
    CirfirmPassword: "",
    CurrentPassword: "",
  });

  useEffect(() => {
    const profileData = JSON.parse(localStorage.getItem("profileData"));
    setBk(profileData);
    setUser({
      Name: profileData.name || "",
      Age: profileData.age || "",
      Password: "",
      CirfirmPassword: "",
      CurrentPassword: "",
    });
  }, []);

  const switchMode = () => {
    setViewMode(!viewMode);
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];

    if (file) {
      try {
        const formData = new FormData();
        formData.append("image", file);

        const res = await axios.post(`${BaseUrl}image`, formData, {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        });

        console.log("Image upload successful", res);
        setBk({ ...bk, image: URL.createObjectURL(file) });
      } catch (error) {
        console.error("Error uploading image", error.message);
      }
    }
  };

  const profileUpdate = async () => {
    var urlencoded = new URLSearchParams();
    urlencoded.append("name", user.Name);
    urlencoded.append("age", user.Age);
    urlencoded.append("password", user.Password);
    urlencoded.append("password_confirmation", user.CirfirmPassword);
    urlencoded.append("current_password", user.CurrentPassword);

    try {
      const config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.put(`${BaseUrl}account`, urlencoded, config);
      toast.success(response.data.response);
      setBk(response.data.data);
    } catch (error) {
      toast.error(error?.response?.data?.error);
      console.log(error.response.data.error, "error");
    }
  };

  return (
    <Card className="lg:ml-56 lg:-mt-10">
      <CardContent>
        <div className="flex flex-col items-center">
          <Avatar
            src={bk?.image || ""}
            alt="user avatar"
            sx={{ width: 200, height: 200, marginBottom: 2 }}
          />
          <Input
            accept="image/*"
            id="icon-button-file"
            type="file"
            style={{ display: "none" }}
            onChange={handleImageChange}
          />
          <label htmlFor="icon-button-file">
            <div className="flex justify-center items-center">
              <IconButton
                className="user-avatar-edit"
                component="span"
                style={{ color: "white" }}
              >
                <CameraIcon sx={{ color: "#252d37" }} />
              </IconButton>
            </div>
          </label>
        </div>

        <form className="mt-2 lg:ml-10 hello">
          <TextField
            label="Name"
            name="Name"
            type="text"
            autoFocus
            required
            value={user.Name}
            onChange={(e) => setUser({ ...user, Name: e.target.value })}
            disabled={viewMode}
            sx={{ marginLeft: "8px" }}
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: user.Name !== undefined && user.Name !== "",
            }}
            defaultValue={bk.name}
          />
          <TextField
            label="Age"
            name="Age"
            required
            value={user.Age}
            onChange={(e) => setUser({ ...user, Age: e.target.value })}
            disabled={viewMode}
            sx={{ marginLeft: "8px" }}
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: user.Age !== undefined && user.Age !== "",
            }}
            defaultValue={bk.age}
          />
        </form>
        <form className="mt-2 lg:ml-10 hello">
          <TextField
            label="Password"
            name="Password"
            type="password"
            required
            value={user.Password}
            onChange={(e) => setUser({ ...user, Password: e.target.value })}
            disabled={viewMode}
            sx={{ marginLeft: "8px" }}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Confirm Password"
            name="CirfirmPassword"
            type="password"
            required
            value={user.CirfirmPassword}
            onChange={(e) =>
              setUser({ ...user, CirfirmPassword: e.target.value })
            }
            disabled={viewMode}
            sx={{ marginLeft: "8px" }}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Current Password"
            name="CurrentPassword"
            type="password"
            required
            value={user.CurrentPassword}
            onChange={(e) =>
              setUser({ ...user, CurrentPassword: e.target.value })
            }
            disabled={viewMode}
            sx={{ marginLeft: "8px" }}
            fullWidth
            margin="normal"
          />
        </form>
        <div
          className="flex justify-center mt-2 bg-[#252d37] w-40 rounded-md"
          style={{ margin: "0 auto" }}
        >
          {viewMode ? (
            <Tooltip title="Edit Profile">
              <IconButton onClick={switchMode} style={{ color: "white" }}>
                Edit
                <EditIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Save Changes">
              <IconButton onClick={profileUpdate} style={{ color: "white" }}>
                <SaveIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default Forms;
