import React, { useState } from 'react';
import '../accests/Css/Login.css'; 
import pic  from '../accests/Images/lestvote.avif'
import axios from 'axios';
import { BaseUrl } from '../BaseUrl/Baseurl';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from 'react-router';
const Login = () => {
  const Navigate = useNavigate()
  const [activeTab, setActiveTab] = useState('login');
  const [email, setEmail] = useState('');
  const [loginPassword, setLoginPassword] = useState('');
  const [signupName, setSignupName] = useState('');
  const [signupAge, setSignupAge] = useState('');
  const [signupEmail, setSignupEmail] = useState('');
  const [signupPassword, setSignupPassword] = useState('');

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const handleLogin =  async (e) => {
    e.preventDefault()
    const formData = new FormData();
    formData.append("email", email);
    formData.append("password", loginPassword);
    try {
      const res = await axios.post(`${BaseUrl}login`, formData);
      localStorage.setItem("Token", res.data.data.token);
      localStorage.setItem("profileData", JSON.stringify(res.data.data));
      console.log(res.data.data, "login data");
      setTimeout(() => {
        Navigate("/");
      }, 2000);
      toast.success(res.data.response);
    } catch (error) {

      console.error("Error:", error.message);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
  
        // Display errors for specific fields (name and age) in toast messages
        if (error.response.data.errors.email) {
          toast.error(` ${error.response.data.errors.email}`);
        }
  
        if (error.response.data.errors.password
          ) {
          toast.error(` ${error.response.data.errors.password
          }`);
        }
  
        // Log the entire errors object for debugging purposes
        console.error("Response data:", error.response.data.errors);
        console.error("Response status:", error.response.status);
        console.error("Response headers:", error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        toast.error("No response received");
        console.error("No response received:", error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        toast.error("Error setting up the request");
        console.error("Error setting up the request:", error.message);
      }
    }
  };

  const handleSignup =  async(e) => {
    e.preventDefault()
    const formData = new FormData();
    formData.append("email", signupEmail);
    formData.append("password", signupPassword);
    formData.append("age", signupAge);
    formData.append("name", signupName);
    try {
      const res = await axios.post(`${BaseUrl}register`, formData);
      localStorage.setItem("Token", res.data.data.token);
      localStorage.setItem("profileData", JSON.stringify(res.data.data));
      console.log(res.data.data, "SignupData data");
      // setTimeout(() => {
      //   Navigate("/");
      // }, 2000);
      toast.success(res.data.response);
    } catch (error) {
      
      console.error("Error:", error.message);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
  
        // Display errors for all fields in toast messages
        const fieldErrors = error.response.data.errors;
        Object.keys(fieldErrors).forEach((fieldName) => {
          toast.error(`${fieldName}: ${fieldErrors[fieldName]}`);
        });
  
        // Log the entire errors object for debugging purposes
        console.error("Response data:", fieldErrors);
        console.error("Response status:", error.response.status);
        console.error("Response headers:", error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        toast.error("No response received");
        console.error("No response received:", error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        toast.error("Error setting up the request");
        console.error("Error setting up the request:", error.message);
      }
    }
  };
  

  return (
    <div className='body'>
    <section className=" mx-auto container py-20 b">
      <div className="grid grid-cols-1 md:grid-cols-2 bg-primary rounded-xl shadow-3xl overflow-hidden">
        <div className="flex flex-col items-center justify-between gap-8 p-5 md:p-10 lg:p-14">
          <div>
            <img className="w-32 md:w-40 h-auto" src="assets/images/logo.png" alt="" />
          </div>
          <div className="tabs w-full">
            <ul id="nav-tab" className="flex items-center justify-center mb-5 md:mb-8">
              <li
                className={`px-7 py-2 text-pastelBlue rounded-md hover:bg-[#111] hover:text-white ${activeTab === 'login' ? 'bg-[#111] text-white' : ''}`}
                onClick={() => handleTabClick('login')}
              >
                <a href="#login">Login</a>
              </li>
              <li
                className={`px-7 py-2 text-pastelBlue rounded-md hover:bg-[#111] hover:text-white ${activeTab === 'signup' ? 'bg-[#111] text-white' : ''}`}
                onClick={() => handleTabClick('signup')}
              >
                <a href="#signup">Sign Up</a>
              </li>
            </ul>

            <div id="tabs-content">
              <div  className={`tab-content flex flex-col gap-5 ${activeTab === 'login' ? '' : 'hidden'}`}>
                <form action="" className="w-full flex flex-col gap-3 sm:gap-4" onSubmit={handleLogin}>
                  <div className="join bg-primary shadow-2xl p-2 flex items-center">
                    <label className="px-3 py-1 text-lg join-item text-pastelBlue border-r border-r-slate-400">
                      <i className="fa-solid fa-phone"></i>
                    </label>
                    <input
                      type="email"
                      className="px-3 py-2 w-full text-sm join-item outline-none bg-transparent"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="join bg-primary shadow-2xl p-2 flex items-center">
                    <label className="px-3 py-1 text-lg join-item text-pastelBlue border-r border-r-slate-400">
                      <i className="fa-solid fa-lock"></i>
                    </label>
                    <input
                      type="password"
                      className="px-3 py-2 w-full text-sm join-item outline-none bg-transparent"
                      placeholder="Enter Password"
                      value={loginPassword}
                      onChange={(e) => setLoginPassword(e.target.value)}
                    />
                  </div>
                 
                  <button className="fill-btn btn-large" type="submit">
                    Login <i className="fa-solid fa-paper-plane"></i>
                  </button>
                </form>
              
              </div>

              <div  className={`tab-content flex flex-col gap-5 ${activeTab === 'signup' ? '' : 'hidden'}`}>
                <form action="" className="w-full flex flex-col gap-3 sm:gap-4" onSubmit={handleSignup}>
                  <div className="join bg-primary shadow-2xl p-2 flex items-center">
                    <label className="px-3 py-1 text-lg join-item text-pastelBlue border-r border-r-slate-400">
                      <i className="fa-solid fa-circle-user"></i>
                    </label>
                    <input
                      type="text"
                      className="px-3 py-2 w-full text-sm join-item outline-none bg-transparent"
                      placeholder="Full Name"
                      value={signupName}
                      onChange={(e) => setSignupName(e.target.value)}
                    />
                  </div>
                  <div className="join bg-primary shadow-2xl p-2 flex items-center">
                    <label className="px-3 py-1 text-lg join-item text-pastelBlue border-r border-r-slate-400">
                    <i class="fas fa-envelope"></i>
                    </label>
                    <input
                      type="email"
                      className="px-3 py-2 w-full text-sm join-item outline-none bg-transparent"
                      placeholder="Email"
                      value={signupEmail}
                      onChange={(e) => setSignupEmail(e.target.value)}
                    />
                  </div>
                  <div className="join bg-primary shadow-2xl p-2 flex items-center">
                    <label className="px-3 py-1 text-lg join-item text-pastelBlue border-r border-r-slate-400">
                    <i class="fas fa-male"> </i>
                    </label>
                    <input
                      type="number"
                      className="px-3 py-2 w-full text-sm join-item outline-none bg-transparent"
                      placeholder=" Age"
                      value={signupAge}
                      onChange={(e) => setSignupAge(e.target.value)}
                    />
                  </div>
                  <div className="join bg-primary shadow-2xl p-2 flex items-center">
                    <label className="px-3 py-1 text-lg join-item text-pastelBlue border-r border-r-slate-400">
                      <i className="fa-solid fa-lock"></i>
                    </label>
                    <input
                      type="password"
                      className="px-3 py-2 w-full text-sm join-item outline-none bg-transparent"
                      placeholder="Enter Password"
                      value={signupPassword}
                      onChange={(e) => setSignupPassword(e.target.value)}
                    />
                  </div>
                  <div className="flex flex-row gap-3 items-center justify-between">
                    <div>
                      <p className="sm:text-sm">
                        By creating an account, you agree and accept our{' '}
                        <a className="underline hover:text-acent" href="k">
                          Terms
                        </a>{' '}
                        and{' '}
                        <a className="underline hover:text-acent" href="k">
                          Privacy Policy
                        </a>
                        .
                      </p>
                    </div>
                  </div>
                  <button className="fill-btn btn-large" type="submit">
                    Sign Up <i className="fa-solid fa-paper-plane"></i>
                  </button>
                </form>
               
              </div>
            </div>
          </div>

          <div>
            <p className="text-center">2023 VideoTv, All Right Reserved</p>
          </div>
        </div>

        <div className="hidden md:block h-full ">
          <img
            className="w-full  object-cover"
            style={{height:"630px"}}
            src={pic}
            alt="Login"
          />
        </div>
      </div>
    </section>
    <ToastContainer />
    </div>

  );
};

export default Login;
