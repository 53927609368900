import React from 'react';
import '../accests/Css/Card.css'

function DashCard() {
  return (
    <>
    <div className='flex flex1'>
      <div className="eb-progress-bar-wrapper lg:ml-52 ">
        <div className="eb-progress-bar html" style={{ '--value': 85, '--col': '#FF5089' }}>
          <progress id="html" min="0" max="100" value="85"></progress>
        </div>
        <label htmlFor="html" className="eb-progress-bar-title">
          <h2>Uers</h2>
        </label>
      </div>

      <div className="eb-progress-bar-wrapper lg:ml-52">
        <div className="eb-progress-bar css" style={{ '--value': 91, '--col': '#FF1659' }}>
          <progress id="css" min="0" max="100" value="91"></progress>
        </div>
        <label htmlFor="css" className="eb-progress-bar-title">
          <h2>Poll</h2>
        </label>
      </div>

    
      <div className="eb-progress-bar-wrapper lg:ml-52">
        <div className="eb-progress-bar py" style={{ '--value': 88, '--col': '#AD0346' }}>
          <progress id="py" min="0" max="100" value="88"></progress>
        </div>
        <label htmlFor="py" className="eb-progress-bar-title">
          <h2>Answer</h2>
        </label>
      </div>
    </div>

    </>
  );
}

export default DashCard;
